import { HttpClientInterface } from 'services/http/types';
import { extractId } from 'utils/services';
import { CreateProjectRequest, ProjectsServiceInterface } from 'services/projects/types';
import { PROJECTS_ENDPOINT } from 'config/projects/endpoints';

class ProjectsService implements ProjectsServiceInterface {
  constructor(private readonly client: HttpClientInterface) {}

  async createProject(request: CreateProjectRequest): Promise<string> {
    const {
      headers: { location },
    } = await this.client.post<void>(PROJECTS_ENDPOINT, request);

    if (!location) {
      throw new Error('Response does not have location header');
    }

    return extractId(location);
  }
}

export default ProjectsService;
