export const AUDITS_ENDPOINT = '/audits';
const AUDITS_AREAS = 'areas';
const AUDITS_LINE_ITEMS = 'line-items';
const LIGHTING_SCHEDULES = 'lighting-schedules';

export const AUDITS_SUMMARY_ENDPOINT = (auditId: string): string =>
  `${AUDITS_ENDPOINT}/${auditId}/summary`;
export const AUDITS_AREAS_ENDPOINT = (auditId: string): string =>
  `${AUDITS_ENDPOINT}/${auditId}/${AUDITS_AREAS}`;
export const AUDITS_AREA_ENDPOINT = (auditId: string, areaId: string): string =>
  `${AUDITS_ENDPOINT}/${auditId}/${AUDITS_AREAS}/${areaId}`;
export const AUDITS_AREA_SUMMARY_ENDPOINT = (auditId: string, areaId: string): string =>
  `${AUDITS_ENDPOINT}/${auditId}/${AUDITS_AREAS}/${areaId}/summary`;
export const AUDITS_LINE_ITEMS_ENDPOINT = (auditId: string): string =>
  `${AUDITS_ENDPOINT}/${auditId}/${AUDITS_LINE_ITEMS}`;
export const AUDITS_LINE_ITEM_ENDPOINT = (auditId: string, lineItemId: string): string =>
  `${AUDITS_ENDPOINT}/${auditId}/${AUDITS_LINE_ITEMS}/${lineItemId}`;
export const AUDITS_LINE_ITEM_QUANTITY_ENDPOINT = (auditId: string, lineItemId: string): string =>
  `${AUDITS_LINE_ITEM_ENDPOINT(auditId, lineItemId)}/quantity`;
export const AUDITS_LIGHTING_SCHEDULES_ENDPOINT = (auditId?: string): string =>
  auditId ? `/${LIGHTING_SCHEDULES}?auditId=${auditId}` : `/${LIGHTING_SCHEDULES}`;
export const AUDITS_LIGHTING_SCHEDULE_ENDPOINT = (
  auditId: string,
  lightingScheduleId: string
): string => `${AUDITS_ENDPOINT}/${auditId}/${LIGHTING_SCHEDULES}/${lightingScheduleId}`;
export const AUDITS_COMPLETE_ENDPOINT = (auditId: string): string =>
  `${AUDITS_ENDPOINT}/${auditId}/complete`;
