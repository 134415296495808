import { FormFieldValidator, FormValidatorResult, FormValidators } from 'utils/form/types';
import { validateArray } from 'utils/form';

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_REGEX = /^\d{3}-\d{3}-\d{4}$/;
const PHONE_NR_REGEX = /^(?:^\d{3}-\d{3}-\d{4}$)?$/; // Not Required Phone
const US_PHONE_WITH_CODE = /^([+][1][(]\d{3}[)][ ]\d{3}-\d{4})$/;
const ZIP_REGEX_SHORT = /^(\d{5})$/; //12345 - Finally Value Quote Filter
const ZIP_REGEX_FULL = /^\d{5}(?:-\d{4})?$/; //12345 OR 12345-1234 - Finally Value Customer Registration
const ACCOUNT_NUMBER_REGEX = /^(\d{10,22})$/; //1234567890 - Finally Value Account Number
const ONLY_LETTERS_REGEX = /^[a-zA-Z \-']*$/;
const ELECTRICITY_RATE_REGEX = /^\d*(\.\d{1,3})?$/;
const ELECTRICITY_RATE_TYPE_REGEX = /^\d{0,3}(\.\d{0,3})?$/;
const ELECTRICITY_RATE_TYPE_REGEX_NOT = /^0\d(\.\d{0,3})?$/;
const FLOAT_TYPE_REGEX = /^\d*(\.\d{0,3})?$/;
const FLOAT_TYPE_REGEX_NOT = /^0\d(\.\d{0,3})?$/;

export const isRequired =
  (errorMessage = 'Please fill out this field'): FormFieldValidator =>
  (value: any): FormValidatorResult =>
    [![null, undefined, '', NaN].includes(value), errorMessage];

export const isEmail =
  (errorMessage = 'Value must be a correct email'): FormFieldValidator =>
  (value: any): FormValidatorResult =>
    [EMAIL_REGEX.test(value), errorMessage];

export const isPhone =
  (errorMessage = 'Value must be a correct phone'): FormFieldValidator =>
  (value: any): FormValidatorResult =>
    [PHONE_REGEX.test(value), errorMessage];

export const isUsPhone =
  (errorMessage = 'Value must be a correct US phone'): FormFieldValidator =>
  (value: any): FormValidatorResult =>
    [US_PHONE_WITH_CODE.test(value), errorMessage];

export const isPhoneNotRequired =
  (errorMessage = 'Value must be a correct phone'): FormFieldValidator =>
  (value: any): FormValidatorResult =>
    [PHONE_NR_REGEX.test(value), errorMessage];

export const isNumber =
  (errorMessage = 'Value must be a number'): FormFieldValidator =>
  (value: any): FormValidatorResult =>
    [!isNaN(value), errorMessage];

export const minValue =
  (
    minValue: number,
    errorMessage = 'Value must be more or equal ' + minValue
  ): FormFieldValidator =>
  (value: any): FormValidatorResult =>
    [!isNaN(value) && value >= minValue, errorMessage];

export const isShortZipCode =
  (errorMessage = 'Value must be a correct ZipCode'): FormFieldValidator =>
  (value: any): FormValidatorResult =>
    [ZIP_REGEX_SHORT.test(value), errorMessage];

export const isFullZipCode =
  (errorMessage = 'Value must be a correct ZipCode'): FormFieldValidator =>
  (value: any): FormValidatorResult =>
    [ZIP_REGEX_FULL.test(value), errorMessage];

export const arrayValidator =
  <Values>(valuesValidators: FormValidators<Values>): FormFieldValidator =>
  (arrayOfValues: Values[]): FormValidatorResult => {
    return validateArray<Values>(valuesValidators, arrayOfValues);
  };

export const maxFileSize =
  (size: number, errorMessage = 'File size is greater than allowed'): FormFieldValidator =>
  (file: File): FormValidatorResult =>
    [!file || file.size <= size, errorMessage];

export const fileTypes =
  (mimeTypes: string[], errorMessage = 'File type is not allowed'): FormFieldValidator =>
  (file: File): FormValidatorResult =>
    [!file || mimeTypes.includes(file.type), errorMessage];

export const maxArrLength =
  (maxLength: number, errorMessage = 'Too much items'): FormFieldValidator =>
  (array: Array<unknown>): FormValidatorResult =>
    [array.length < maxLength, errorMessage];

export const minArrLength =
  (minLength: number, errorMessage = 'Too less items'): FormFieldValidator =>
  (array: Array<unknown>): FormValidatorResult =>
    [array.length >= minLength, errorMessage];

export const isAccountNumber =
  (errorMessage = 'Value must have length between 10 and 22'): FormFieldValidator =>
  (value: any): FormValidatorResult =>
    [ACCOUNT_NUMBER_REGEX.test(value), errorMessage];

export const maxStringLength =
  (
    maxLength: number,
    errorMessage = `Value must have length ${maxLength} or less`
  ): FormFieldValidator =>
  (value: string): FormValidatorResult =>
    [!value || value.length <= maxLength, errorMessage];

export const onlyLetters =
  (errorMessage = 'Value must contain only letters'): FormFieldValidator =>
  (value: any): FormValidatorResult =>
    [ONLY_LETTERS_REGEX.test(value), errorMessage];

export const greaterThan =
  (min: number, errorMessage = `Value must be greater than ${min}`): FormFieldValidator =>
  (value: any): FormValidatorResult =>
    [value > min, errorMessage];

export const lessThan =
  (min: number, errorMessage = `Value must be less than ${min}`): FormFieldValidator =>
  (value: any): FormValidatorResult =>
    [value <= min, errorMessage];

export const isElectricityRateType =
  (errorMessage = 'Value must be a correct number'): FormFieldValidator =>
  (value: any): FormValidatorResult =>
    [
      (value === '' || ELECTRICITY_RATE_TYPE_REGEX.test(value)) &&
        !ELECTRICITY_RATE_TYPE_REGEX_NOT.test(value),
      errorMessage,
    ];

export const isElectricityRate =
  (errorMessage = 'Value must be a correct number'): FormFieldValidator =>
  (value: any): FormValidatorResult =>
    [Number(value) && ELECTRICITY_RATE_REGEX.test(value), errorMessage];

export const isNoneZeroFloatType =
  (errorMessage = 'Value must be a correct number'): FormFieldValidator =>
  (value: any): FormValidatorResult =>
    [
      (value === '' || FLOAT_TYPE_REGEX.test(value)) && !FLOAT_TYPE_REGEX_NOT.test(value),
      errorMessage,
    ];

export const inList =
  (list: Array<any>, errorMessage = 'Value must be in list'): FormFieldValidator =>
  (value: any): FormValidatorResult =>
    [list.includes(value), errorMessage];
