import { HttpClientInterface } from 'services/http/types';
import { CmsServiceInterface, Faq, FaqItem, LedTerm, Partner, StateTax } from 'services/cms/types';
import {
  CMS_FAQS_ABOUT_US_ENDPOINT,
  CMS_FAQS_ENDPOINT,
  CMS_LED_TERMS_OF_SERVICE_ENDPOINT,
  CMS_PARTNERS_ENDPOINT,
  CMS_STATE_TAXES_ENDPOINT,
} from 'config/cms/endpoints';
import { createCmsAssetUrl } from 'config/cms';

class CmsService implements CmsServiceInterface {
  constructor(private readonly client: HttpClientInterface) {}

  private stateTaxesCache = null;
  private ledTermsCache = null;

  async getFAQs(): Promise<Faq[]> {
    const { data } = await this.client.get<{ data: Faq[] }>(CMS_FAQS_ENDPOINT);

    // @ts-ignore
    return data.data;
  }

  async getAboutUsFAQs(): Promise<FaqItem[]> {
    const { data } = await this.client.get<{ data: Faq[] }>(CMS_FAQS_ABOUT_US_ENDPOINT);

    // @ts-ignore
    return data.data.length ? data.data[0].faqs : [];
  }

  async getPartners(): Promise<Partner[]> {
    const { data } = await this.client.get<{ data: Partner[] }>(CMS_PARTNERS_ENDPOINT);

    // @ts-ignore
    return data.data.map((item) => ({
      ...item,
      desktopImage: createCmsAssetUrl(item.desktop_image),
      mobileImage: createCmsAssetUrl(item.mobile_image),
    }));
  }

  async getStateTaxes(): Promise<StateTax[]> {
    if (this.stateTaxesCache === null) {
      const { data } = await this.client.get<{ data: StateTax[] }>(CMS_STATE_TAXES_ENDPOINT);
      // @ts-ignore
      this.stateTaxesCache = data.data;
    }
    return this.stateTaxesCache;
  }

  async getLedTerms(): Promise<LedTerm[]> {
    if (this.ledTermsCache === null) {
      const { data } = await this.client.get<{ data: LedTerm[] }>(
        CMS_LED_TERMS_OF_SERVICE_ENDPOINT
      );
      // @ts-ignore
      this.ledTermsCache = data.data;
    }
    return this.ledTermsCache;
  }
}

export default CmsService;
