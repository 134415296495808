import { HttpClientInterface } from 'services/http/types';
import {
  FIXTURE_CATEGORIES_ENDPOINT,
  FIXTURE_IDENTIFICATIONS_ENDPOINT,
  FIXTURE_IDENTIFICATIONS_GET_ENDPOINT,
  FIXTURE_MOUNTINGS_ENDPOINT,
  FIXTURES_ENDPOINT,
  FIXTURES_IDENTIFIED_INSTALLATIONS_ENDPOINT,
  FIXTURES_LAMPS_ENDPOINT,
} from 'config/fixtureIdentifications/endpoints';
import {
  CreateFixtureIdentificationRequest,
  CreateIdentifiedFixtureInstallationRequest,
  Fixture,
  FixtureCategoriesResponse,
  FixtureCategory,
  FixtureIdentificationServiceInterface,
  IdentifiedFixture,
  IdentifiedFixtureInstallation,
  Lamp,
  MountingsByLocationResponse,
} from 'services/fixtureIdentification/types';
import { extractId, serializeParams } from 'utils/services';
import { HEADER_PAGINATION_TOTAL_ELEMENTS } from 'config/services';

interface CategoryItem {
  category: FixtureCategory;
  subcategories?: FixtureCategory[];
}

class FixtureIdentificationService implements FixtureIdentificationServiceInterface {
  constructor(private readonly client: HttpClientInterface) {}

  async getIdentifiedFixtures(auditId: string): Promise<IdentifiedFixture[]> {
    const { data } = await this.client.get<IdentifiedFixture[]>(FIXTURE_IDENTIFICATIONS_ENDPOINT, {
      auditId,
    });
    return data;
  }

  async getIdentifiedFixture(
    auditId: string,
    identifiedFixtureId: string
  ): Promise<IdentifiedFixture> {
    const identifiedFixtures = await this.getIdentifiedFixtures(auditId);
    return identifiedFixtures.find((item) => item.identifiedFixtureId === identifiedFixtureId);
  }

  async getIdentifiedFixtureByIdentifiedFixtureId(
    identifiedFixtureId: string
  ): Promise<IdentifiedFixture> {
    const { data } = await this.client.get<IdentifiedFixture>(
      FIXTURE_IDENTIFICATIONS_GET_ENDPOINT(identifiedFixtureId)
    );
    return data;
  }

  async createIdentifiedFixture(requestBody: CreateFixtureIdentificationRequest): Promise<string> {
    const {
      headers: { location },
    } = await this.client.post(FIXTURE_IDENTIFICATIONS_ENDPOINT, requestBody, serializeParams);
    return extractId(location);
  }

  async getMountingsByLocation(): Promise<MountingsByLocationResponse> {
    const { data } = await this.client.get<MountingsByLocationResponse>(FIXTURE_MOUNTINGS_ENDPOINT);
    return data;
  }

  async getFixtureCategories(queryParams = {}): Promise<FixtureCategoriesResponse> {
    const { data } = await this.client.get<FixtureCategoriesResponse>(
      FIXTURE_CATEGORIES_ENDPOINT,
      queryParams
    );
    return FixtureIdentificationService.sortCategories(await data);
  }

  async getFixtures(queryParams = {}): Promise<Fixture[]> {
    const { data } = await this.client.get<Fixture[]>(FIXTURES_ENDPOINT, queryParams);
    return data;
  }

  async getLamps(queryParams = {}): Promise<{ lamps: Lamp[]; total: number }> {
    const { data, headers } = await this.client.get<Lamp[]>(
      FIXTURES_LAMPS_ENDPOINT,
      queryParams,
      serializeParams
    );
    return { lamps: await data, total: await headers[HEADER_PAGINATION_TOTAL_ELEMENTS] };
  }

  async getIdentifiedFixtureInstallation(
    identifiedFixtureId: string
  ): Promise<IdentifiedFixtureInstallation> {
    const { data } = await this.client.get<IdentifiedFixtureInstallation[]>(
      FIXTURES_IDENTIFIED_INSTALLATIONS_ENDPOINT,
      { identifiedFixtureId, page: 1, size: 10 },
      serializeParams
    );
    return data[0];
  }

  async createIdentifiedFixtureInstallation(
    requestBody: CreateIdentifiedFixtureInstallationRequest
  ): Promise<string> {
    const {
      headers: { location },
    } = await this.client.post(
      FIXTURES_IDENTIFIED_INSTALLATIONS_ENDPOINT,
      requestBody,
      serializeParams
    );
    return extractId(location);
  }

  private static sortCategories(categories: FixtureCategoriesResponse): FixtureCategoriesResponse {
    categories.sort((a: CategoryItem, b: CategoryItem) => {
      if (b.subcategories) {
        return 1;
      }
      if (a.subcategories) {
        return -1;
      }
      return 0;
    });

    return categories;
  }
}

export default FixtureIdentificationService;
