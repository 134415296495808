import {
  AUDITS_AREA_ENDPOINT,
  AUDITS_AREA_SUMMARY_ENDPOINT,
  AUDITS_AREAS_ENDPOINT,
  AUDITS_COMPLETE_ENDPOINT,
  AUDITS_ENDPOINT,
  AUDITS_LIGHTING_SCHEDULE_ENDPOINT,
  AUDITS_LIGHTING_SCHEDULES_ENDPOINT,
  AUDITS_LINE_ITEM_ENDPOINT,
  AUDITS_LINE_ITEM_QUANTITY_ENDPOINT,
  AUDITS_LINE_ITEMS_ENDPOINT,
  AUDITS_SUMMARY_ENDPOINT,
} from 'config/audit/endpoints';
import {
  Area,
  AreaSummeryResponse,
  AuditServiceInterface,
  CreateAreaRequest,
  CreateLightingScheduleRequest,
  CreateLineItemRequest,
  LightingSchedule,
  LineItem,
  ProjectAudit,
  SummaryResponseInterface,
} from 'services/audit/types';
import { HttpClientInterface } from 'services/http/types';
import { extractId, serializeParams } from 'utils/services';

class AuditService implements AuditServiceInterface {
  constructor(private readonly client: HttpClientInterface) {}

  async createAudit(projectId: string): Promise<string> {
    const {
      headers: { location },
    } = await this.client.post(AUDITS_ENDPOINT, { projectId }, serializeParams);
    return extractId(location);
  }

  async createArea(auditId: string, requestBody: CreateAreaRequest): Promise<string> {
    const {
      headers: { location },
    } = await this.client.post(AUDITS_AREAS_ENDPOINT(auditId), requestBody, serializeParams);
    return extractId(location);
  }

  async deleteArea(auditId: string, areaId: string): Promise<{ status: number }> {
    const { status } = await this.client.delete(AUDITS_AREA_ENDPOINT(auditId, areaId));
    return { status };
  }

  async getAreas(auditId: string): Promise<Area[]> {
    const { data } = await this.client.get<Area[]>(AUDITS_AREAS_ENDPOINT(auditId));
    return data;
  }

  async createLineItem(auditId: string, requestBody: CreateLineItemRequest): Promise<string> {
    const {
      headers: { location },
    } = await this.client.post(AUDITS_LINE_ITEMS_ENDPOINT(auditId), requestBody, serializeParams);
    return extractId(location);
  }

  async updateLineItemQuantity(
    auditId: string,
    lineItemId: string,
    quantity: number
  ): Promise<void> {
    await this.client.put(
      AUDITS_LINE_ITEM_QUANTITY_ENDPOINT(auditId, lineItemId),
      { quantity },
      serializeParams
    );
  }

  async getAreaSummary(auditId: string, areaId: string): Promise<AreaSummeryResponse> {
    const { data } = await this.client.get<AreaSummeryResponse>(
      AUDITS_AREA_SUMMARY_ENDPOINT(auditId, areaId)
    );
    return data;
  }

  async getSummary(auditId: string): Promise<SummaryResponseInterface> {
    const { data } = await this.client.get<SummaryResponseInterface>(
      AUDITS_SUMMARY_ENDPOINT(auditId)
    );
    return data;
  }

  async deleteLineItem(auditId: string, lineItemId: string): Promise<{ status: number }> {
    const { status } = await this.client.delete(AUDITS_LINE_ITEM_ENDPOINT(auditId, lineItemId));
    return { status };
  }

  async getLineItems(auditId: string, areaId: string): Promise<LineItem[]> {
    const { data } = await this.client.get<LineItem[]>(AUDITS_LINE_ITEMS_ENDPOINT(auditId), {
      areaId,
    });
    return data;
  }

  async createLightingSchedule(requestBody: CreateLightingScheduleRequest): Promise<string> {
    const {
      headers: { location },
    } = await this.client.post(AUDITS_LIGHTING_SCHEDULES_ENDPOINT(), requestBody, serializeParams);
    return extractId(location);
  }

  async getLightingSchedules(auditId: string): Promise<LightingSchedule[]> {
    const { data } = await this.client.get<LightingSchedule[]>(
      AUDITS_LIGHTING_SCHEDULES_ENDPOINT(auditId)
    );
    return data;
  }

  async getLightingSchedule(
    auditId: string,
    lightingScheduleId: string
  ): Promise<LightingSchedule> {
    const { data } = await this.client.get<LightingSchedule>(
      AUDITS_LIGHTING_SCHEDULE_ENDPOINT(auditId, lightingScheduleId)
    );
    return data;
  }

  async completeAudit(auditId: string): Promise<void> {
    await this.client.put(AUDITS_COMPLETE_ENDPOINT(auditId));
  }

  async getProjectAudits(projectId: string): Promise<ProjectAudit[]> {
    const { data } = await this.client.get<ProjectAudit[]>(AUDITS_ENDPOINT, { projectId });
    return data;
  }
}

export default AuditService;
