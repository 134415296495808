import { LED_BASE_ROUTE } from 'config/led/routes';
import { RETAIL_BASE_ROUTE } from 'config/retail/routes';
import { evChargingBg, retailEnergyBg } from 'assets/images/home';
import { EV_CHARGING_URL, RETAIL_URL } from 'config/seo/urls';

export const E5_APP_LINK = 'https://energy5.page.link/services';
export const E5_APP_COOKIE = 'e5AppCookie';
export const E5_APP_COOKIE_DAYS = 365;
export const E5_APP_COOKIE_OPTIONS = { maxAge: E5_APP_COOKIE_DAYS * 24 * 3600 };
export const E5_APP_ROUTES = [LED_BASE_ROUTE, RETAIL_BASE_ROUTE, '/ev-charging'];


export const STATIC_SERVICES = [
  {
    key: 'retail-energy',
    title: 'home.header.new.retail-energy',
    caption: 'home.header.new.upgrade-your-lightning',
    imgSrc: retailEnergyBg,
    zipInput: true,
    url: RETAIL_URL,
    description: 'Be it business or residential electricity rates, just fill the form and get the best energy plans in your area. Start save on energy today',
  },
  {
    key: 'ev-charging',
    title: 'home.header.new.ev-charging',
    caption: 'home.header.new.start-ev-charging',
    imgSrc: evChargingBg,
    url: EV_CHARGING_URL,
    description: 'From permitting to purchase and installation to software management, financing, and incentive paperwork – Energy5 offers end-to-end turnkey EV Charging solutions for your business',
  },
]