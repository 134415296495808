import { NextApiResponse } from 'next';
import { HttpStatus } from 'services/http/types';

export const handleError = (e: Error): void => {
  console.error(e);
};

export const handleNextApiError = (e: Error, res: NextApiResponse) => {
  console.error(e);
  handleError(e);
  res.statusCode = HttpStatus.INTERNAL_SERVER_ERROR;
  res.end(JSON.stringify({ message: 'Something wrong on server side' }));
};
