import React, { FC, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { useRouter } from 'next/router';
import { E5_APP_COOKIE, E5_APP_LINK, E5_APP_ROUTES } from 'config/e5app';
import { PageLink } from "components/common/atoms";

const useStyle = makeStyles((theme: Theme) => ({
  useAppLink: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 72,
    height: 27,
    right: 80,
    top: 20,
    borderRadius: 7,
    backgroundColor: '#FFA700',
    color: 'white',
    zIndex: 1200,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

const MobileAppLink: FC = () => {
  const classes = useStyle();
  const { asPath } = useRouter();
  // const [cookies] = useCookies();
  const [hasCookies, setHasCookies] = useState<boolean>(false);
  const visible = asPath === '/' || E5_APP_ROUTES.some((route) => asPath.startsWith(route));

  /*useEffect(() => {
    setHasCookies(!!cookies[E5_APP_COOKIE]);
  }, [cookies]);*/

  if (!visible || !hasCookies) {
    return null;
  }

  return (
    <PageLink href={E5_APP_LINK} className={classes.useAppLink} rel="noreferrer nofollow">
      use app
    </PageLink>
  );
};

export default MobileAppLink;
