import { SessionInterface } from './types';

class LocalStorageSession implements SessionInterface {
  constructor(private readonly permanently: boolean) {}

  get storage(): Storage {
    return this.permanently ? localStorage : sessionStorage;
  }

  set(name: string, value: string): void {
    this.storage.setItem(name, value);
  }

  get(name: string): string {
    return this.storage.getItem(name) || '';
  }

  has(name: string): boolean {
    return !!this.storage.getItem(name);
  }

  remove(name: string): void {
    return this.storage.removeItem(name);
  }

  clear(): void {
    return this.storage.clear();
  }

  read = (rootKey: string, key: string): any => {
    const root = this.has(rootKey) ? JSON.parse(this.get(rootKey)) : {};
    return root[key];
  };

  write = (rootKey: string, key: string, data: any): void => {
    const root = this.has(rootKey) ? JSON.parse(this.get(rootKey)) : {};
    root[key] = data;
    this.set(rootKey, JSON.stringify(root));
  };
}

export default LocalStorageSession;
