import {
  GetBestPlansApiRequest,
  GetBestPlansRequest,
  GetBestPriceApiRequest,
  GetBestPriceRequest,
  GetPlansApiRequest,
  GetPlansRequest,
} from 'services/quotes/types';
import { objectRenameKeys } from 'utils/common/object';

const plansFieldMap = {
  zoneId: 'zone-id',
  utilityId: 'utility-id',
  classificationId: 'classification-id',
  annualUsage: 'annual-usage.amount',
  unit: 'annual-usage.unit',
  priceToCompare: 'compare-to-price',
  effectiveDate: 'effective-date',
  customerType: 'customer-type',
  zipCode: 'zip-code',
  preferredTerms: 'preferred-terms',
  brokerId: 'broker-id',
};

const plansSortMap = {
  greenEnergy: 'green-energy',
  estimatedSavings: 'estimated-savings',
};

export const transformGetBestPlansRequest = (
  request: GetBestPlansRequest
): GetBestPlansApiRequest => objectRenameKeys<GetBestPlansApiRequest>(plansFieldMap, request);

export const transformGetPlansRequest = (request: GetPlansRequest): GetPlansApiRequest => {
  const clone = { ...request };
  const [sortBy, orderBy] = request.sort.split(',');
  const field = plansSortMap[sortBy] || sortBy;

  return {
    ...objectRenameKeys<GetPlansApiRequest>(plansFieldMap, clone),
    sort: `${field},${orderBy}`,
  };
};

export const transformGetBestPriceRequest = (
  request: GetBestPriceRequest
): GetBestPriceApiRequest => objectRenameKeys<GetBestPriceApiRequest>(plansFieldMap, request);
