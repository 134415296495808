import { HttpClientInterface } from 'services/http/types';
import {
  LIGHTING_RETROFIT_ORDERS_ENDPOINT,
  RETAIL_ENERGY_ORDERS_ENDPOINT,
} from 'config/orders/endpoints';
import {
  CreateCommercialOrderRequest,
  CreateLightingRetrofitOrder,
  CreateResidentialOrderRequest,
  OrdersServiceInterface,
} from 'services/orders/types';
import { extractId } from 'utils/services';

class OrdersService implements OrdersServiceInterface {
  constructor(private readonly client: HttpClientInterface) {}

  async createCommercialOrder(request: CreateCommercialOrderRequest): Promise<string> {
    const {
      headers: { location },
    } = await this.client.post<void>(RETAIL_ENERGY_ORDERS_ENDPOINT, request);

    if (!location) {
      throw new Error('Response does not have location header');
    }

    return extractId(location);
  }

  async createResidentialOrder(request: CreateResidentialOrderRequest): Promise<string> {
    const {
      headers: { location },
    } = await this.client.post<void>(RETAIL_ENERGY_ORDERS_ENDPOINT, request);

    if (!location) {
      throw new Error('Response does not have location header');
    }

    return extractId(location);
  }

  async createLightingRetrofitOrder(request: CreateLightingRetrofitOrder): Promise<string> {
    const {
      headers: { location },
    } = await this.client.post<void>(LIGHTING_RETROFIT_ORDERS_ENDPOINT, request);

    if (!location) {
      throw new Error('Response does not have location header');
    }

    return extractId(location);
  }
}

export default OrdersService;
