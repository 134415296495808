import { SessionInterface } from 'utils/services/types';
import {
  ResidentialCustomerInformation,
  ResidentialMailingBillingInformation,
  ResidentialSessionInterface,
  ResidentialUtilityAccountInformation,
} from 'services/retail/residential/types';
import { Plan } from 'services/quotes/types';
import { UploadedBill } from 'services/retail/commercial/types';
import { AnnualUsage } from 'services/orders/types';
import { BillSamples } from 'services/delivery/types';

const RESIDENTIAL_SESSION_KEY = 'RESIDENTIAL_SESSION';

class ResidentialSession implements ResidentialSessionInterface {
  constructor(private readonly session: SessionInterface) {}

  clear() {
    this.session.remove(RESIDENTIAL_SESSION_KEY);
  }

  read = (key: string) => this.session.read(RESIDENTIAL_SESSION_KEY, key);

  write = (key: string, data: any) => this.session.write(RESIDENTIAL_SESSION_KEY, key, data);

  hasStarted(): boolean {
    return !!this.getSelectedPlan();
  }

  setSelectedPlan = (plan: Plan) => {
    this.write('selectedPlan', plan);
  };

  getSelectedPlan = (): Plan | undefined => {
    return this.read('selectedPlan');
  };

  setAnnualUsage = (annualUsage: AnnualUsage) => {
    this.write('annualUsage', annualUsage);
  };

  getAnnualUsage = (): AnnualUsage | undefined => {
    return this.read('annualUsage');
  };

  getBackUrl = (): string => {
    return this.read('backUrl');
  };

  setBackUrl = (backUrl: string): void => {
    this.write('backUrl', backUrl);
  };

  getEffectiveDate = (): string => {
    return this.read('effectiveDate');
  };

  setEffectiveDate = (effectiveDate: string): void => {
    this.write('effectiveDate', effectiveDate);
  };

  setCustomerInformation = (customerInformation: ResidentialCustomerInformation) => {
    this.write('customerInformation', customerInformation);
  };

  getCustomerInformation = (): ResidentialCustomerInformation => {
    return this.read('customerInformation');
  };

  setEsignature = (esignature: string) => {
    this.write('customerInformation', { esignature });
  };

  unsetEsignature = () => {
    const data = this.read('customerInformation');
    if (!data) {
      return;
    }
    if (data.esignature) {
      delete data.esignature;
    }
    this.write('customerInformation', { ...data });
  };
  5;

  setUtilityAccountInformation = (
    utilityAccountInformation: Partial<ResidentialUtilityAccountInformation>
  ) => {
    this.write('utilityAccountInformation', utilityAccountInformation);
  };

  getUtilityAccountInformation = (): ResidentialUtilityAccountInformation => {
    return this.read('utilityAccountInformation');
  };

  setMailingBillingInformation = (
    mailingBillingInformation: ResidentialMailingBillingInformation
  ) => {
    this.write('mailingBillingInformation', mailingBillingInformation);
  };

  getMailingBillingInformation = (): ResidentialMailingBillingInformation => {
    return this.read('mailingBillingInformation');
  };

  hasMailingBillingInformation = (): boolean => {
    return !!this.getMailingBillingInformation() && !!this.getMailingBillingInformation().address;
  };

  getUploadedBills = (): UploadedBill[] => {
    return this.read('uploadedBills') || [];
  };

  setUploadedBills = (uploadedBills: UploadedBill[]): void => {
    this.write('uploadedBills', uploadedBills);
  };

  setBillSamples = (billSamples: BillSamples) => {
    this.write('billSamples', billSamples);
  };

  getBillSamples = (): BillSamples => {
    return this.read('billSamples');
  };
}

export default ResidentialSession;
