import { objectKeysToSnakeCase, objectRemoveEmptyValues } from 'utils/common/object';
import { Plan } from 'services/quotes/types';

export enum EVENT_TRIGGER_PARAM {
  userInteraction = 'user interaction',
  pageLoad = 'page load',
}

const gtmEvents = {
  // Retail
  RETAIL_SELECT_SERVICE: 'select_service',
  RETAIL_ENTER_ZIP_CODE: 'enter_zipcode',
  RETAIL_CONFIRM_CUSTOMER_TYPE: 'confirm_customer_type',
  RETAIL_SELECT_COMMODITY: 'select_commodity',
  RETAIL_GET_QUOTE: 'get_quote',
  RETAIL_FOCUS_LEAD: 'retail_focus_lead',
  RETAIL_SUBMIT_LEAD: 'retail_submit_lead',
  RETAIL_POPUP_CUSTOM_QUOTE: 'retail_popup_custom_quote',
  RETAIL_GET_CUSTOM_QUOTE: 'retail_get_custom_quote',
  RETAIL_FOCUS_STAY_UPDATE: 'retail_focus_stay_update',
  RETAIL_SUBMIT_STAY_UPDATE: 'retail_submit_stay_update',
  RETAIL_VIEW_PLANS: 'view_plans',
  RETAIL_SELECT_UTILITY: 'select_utility',
  RETAIL_SEARCH_UTILITY: 'search_utility',
  RETAIL_SEARCH_RATE_CLASS: 'search_rate_class',
  RETAIL_SELECT_RATE_CLASS: 'select_rate_class',
  RETAIL_SEARCH_ZONE: 'search_zone',
  RETAIL_SELECT_ZONE: 'select_zone',
  RETAIL_SELECT_STATE: 'select_state',
  RETAIL_SELECT_PAGE: 'select_page',
  RETAIL_SORT_BY: 'sort_by',
  RETAIL_CHANGE_START_DATE: 'change_start_date',
  RETAIL_SELECT_TERM: 'select_term',
  RETAIL_VIEW_PLAN: 'view_plan',
  RETAIL_SELECT_PLAN: 'select_plan',
  RETAIL_SELECT_BEST_PLAN: 'select_best_plan',
  RETAIL_CHANGE_PLAN: 'change_plan',
  RETAIL_ADD_CUSTOMER_INFO: 'add_customer_info',
  RETAIL_ADD_UTILITY_ACCOUNT_INFO: 'add_utility_account_info',
  RETAIL_ADD_MAILING_INFO: 'add_mailing_info',
  RETAIL_PLACE_RETAIL_ENERGY_ORDER: 'place_retail_energy_order',
  RETAIL_SIGN_CONTRACT: 'sign_contract',
  RETAIL_DECLINE_CONTRACT: 'decline_contract',
  RETAIL_BEGIN_CHECKOUT: 'begin_checkout',
  RETAIL_ENTER_ANNUAL_USAGE: 'enter_annual_usage',
  RETAIL_COMPARE_PRICE: 'compare_price',

  // LED
  LED_PROJECT_BEGIN_CREATION: 'project_begin_creation',
  LED_PROJECT_CREATION: 'project_creation',
  LED_PROJECT_CONTINUE: 'project_continue',
  LED_AUDIT_START: 'audit_start',
  LED_AUDIT_BEGIN_AREA_CREATION: 'audit_begin_area_creation',
  LED_AUDIT_BEGIN_ELECTRICITY_RATE_CALCULATOR: 'audit_begin_electricity_rate_calcul',
  LED_AUDIT_CONFIRM_ELECTRICITY_RATE_CALCULATOR: 'audit_confirm_electricity_rate_calcul',
  LED_LIGHTING_SCHEDULE_BEGIN_CREATION: 'lighting_schedule_begin_creation',
  LED_LIGHTING_SCHEDULE_CREATE: 'lighting_schedule_create',
  LED_AUDIT_SELECT_LIGHTING_SCHEDULE: 'audit_select_lighting_schedule',
  LED_AUDIT_CREATE_AREA: 'audit_create_area',
  LED_AUDIT_SELECT_AREA: 'audit_select_area',
  LED_AUDIT_BEGIN_LINE_ITEM_CREATION: 'audit_begin_line_item_creation',
  LED_FIXTURE_IDENTIFICATION_BEGIN: 'fixture_identification_begin',
  LED_FIXTURE_UPLOAD_PICTURE: 'fixture_upload_picture',
  LED_FIXTURE_SELECT_MOUNTING: 'fixture_select_mounting',
  LED_FIXTURE_SELECT_CATEGORY: 'fixture_select_category',
  LED_FIXTURE_SEARCH_LAMP: 'fixture_search_lamp',
  LED_FIXTURE_SEARCH_NO_LAMPS: 'fixture_search_no_lamps',
  LED_FIXTURE_CONFIRM_LAMP: 'fixture_confirm_lamp',
  LED_FIXTURE_CONFIRM_NUMBER_OF_LAMPS: 'fixture_confirm_number_of_lamps',
  LED_FIXTURE_CONFIRM_IDENTIFIED_FIXTURE: 'fixture_confirm_identified_fixture',
  LED_AUDIT_SELECT_IDENTIFIED_FIXTURE: 'audit_select_identified_fixture',
  LED_AUDIT_CREATE_LINE_ITEM: 'audit_create_line_item',
  LED_AUDIT_CHANGE_LINE_ITEM_QUANTITY: 'audit_change_line_item_quantity',
  LED_AUDIT_REMOVE_LINE_ITEM: 'audit_remove_line_item',
  LED_AUDIT_REMOVE_AREA: 'audit_remove_area',
  LED_AUDIT_VIEW_SUMMARY: 'audit_view_summary',
  LED_COMPLETE_AUDIT: 'complete_audit',
  LED_SPECIFICATION_GET_RECOMMENDATIONS: 'specification_get_recommendations',
  LED_SPECIFICATION_SELECT_EXISTING_ITEM: 'specification_select_existing_item',
  LED_SPECIFICATION_SELECT_AREA: 'specification_select_area',
  LED_SPECIFICATION_VIEW_RECOMMENDATIONS: 'specification_view_recommendations',
  LED_SPECIFICATION_VIEW_NO_RECOMMENDATIONS: 'specification_view_no_recommendations',
  LED_SPECIFICATION_ADD_REPLACEMENT: 'specification_add_replacement',
  LED_SPECIFICATION_CHANGE_REPL_QUANTITY: 'specification_change_repl_quantity',
  LED_SPECIFICATION_REMOVE_REPLACEMENT: 'specification_remove_replacement',
  LED_SPECIFICATION_VIEW_REPLACEMENTS: 'specification_view_replacements',
  LED_LIGHTING_RETROFIT_BEGIN_CHECKOUT: 'lighting_retrofit_begin_checkout',
  LED_LIGHTING_RETROFIT_ADD_CUSTOMER_INFO: 'lighting_retrofit_add_customer_info',
  LED_LIGHTING_RETROFIT_ADD_SHIPPING_INFO: 'lighting_retrofit_add_shipping_info',
  LED_LIGHTING_RETROFIT_ADD_BILLING_INFO: 'lighting_retrofit_add_billing_info',
  LED_LIGHTING_RETROFIT_ORDER: 'place_lighting_retrofit_order',

  // Scan
  SCAN_BILL_CAMERA_OPEN: 'scan_bill_camera_open',
  SCAN_BILL_CAMERA_TAKE_PICTURE: 'scan_bill_camera_take_picture',
  SCAN_BILL_CAMERA_REMOVE_PICTURE: 'scan_bill_camera_remove_picture',
  SCAN_BILL_CAMERA_CLOSE: 'scan_bill_camera_close',
  SCAN_BILL_UPLOAD_START: 'scan_bill_upload_start',
  SCAN_BILL_UPLOAD_CHOOSE_FILES: 'scan_bill_upload_choose_files',
  SCAN_BILL_UPLOAD_REMOVE_FILE: 'scan_bill_upload_remove_file',
  SCAN_BILL_UPLOAD_END: 'scan_bill_upload_end',
  SCAN_BILL_SCROLL_UP: 'scan_bill_scroll_up',
  SCAN_BILL_BACK: 'scan_bill_back',
  SCAN_BILL_SUBMIT: 'scan_bill_submit',
  SCAN_BILL_HOME: 'scan_bill_home',

  // ABOUT US
  ABOUT_CONTACT_US: 'about_contact_us',
  ABOUT_RETAIL_EXPLORE: 'about_retail_explore',
  ABOUT_EV_EXPLORE: 'about_ev_explore',
  ABOUT_LED_EXPLORE: 'about_led_explore',
  ABOUT_FAQ: 'about_faq',
};

const gtmTrack = (event: string, params: Record<string, any> = {}) => {
  if (typeof window !== 'undefined') {
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({ ...params, event });
  }
};

const createPlanParams = (plan: Plan) =>
  objectRemoveEmptyValues({
    term: plan.term.value,
    annual_usage: plan.annualUsage.maximum,
    supplier: plan.supplier.name,
    price: plan.price.amount,
    billing_method: plan.billingMethod,
    commodity: plan.commodity,
    effective_date: plan.effectiveDate,
  });

export const createRetailViewPlansParams = (params: Record<string, any>) =>
  objectRemoveEmptyValues({
    ...objectKeysToSnakeCase(params),
    state: params.state ? params.state['state'] : null,
    rate_class: params.rateClass ? params.rateClass['name'] : null,
    term: params.term ? Array.of(params.term).join(',') : null,
    utility: params.utility ? params.utility['name'] : null,
    zone: params.zone ? params.zone['name'] : null,
    sort: params.sort,
    utility_id: null,
    classification_id: null,
    zone_id: null,
    sort_by: null,
    order_by: null,
  });

export const triggerSignContractEvent = () => {
  gtmTrack(gtmEvents.RETAIL_SIGN_CONTRACT, {});
};

export const triggerDeclineContractEvent = () => {
  gtmTrack(gtmEvents.RETAIL_DECLINE_CONTRACT, {});
};

export const triggerSelectServiceEvent = (service: string) => {
  gtmTrack(gtmEvents.RETAIL_SELECT_SERVICE, { service: service });
};

export const triggerRetailEnterZipCodeEvent = (
  zipCode: string,
  trigger = EVENT_TRIGGER_PARAM.userInteraction
) => {
  if (zipCode.length === 5) {
    gtmTrack(gtmEvents.RETAIL_ENTER_ZIP_CODE, { zip_code: zipCode, trigger });
  }
};

export const triggerRetailConfirmCustomerTypeEvent = (
  customerType: string,
  trigger = EVENT_TRIGGER_PARAM.userInteraction
) => {
  gtmTrack(gtmEvents.RETAIL_CONFIRM_CUSTOMER_TYPE, { customer_type: customerType, trigger });
};

export const triggerRetailSelectCommodityEvent = (
  commodity: string,
  trigger = EVENT_TRIGGER_PARAM.userInteraction
) => {
  gtmTrack(gtmEvents.RETAIL_SELECT_COMMODITY, { commodity: commodity, trigger });
};

export const triggerRetailSelectUtilityEvent = (
  utility: string,
  trigger = EVENT_TRIGGER_PARAM.userInteraction
) => {
  gtmTrack(gtmEvents.RETAIL_SELECT_UTILITY, { utility: utility, trigger });
};

export const triggerRetailSelectRateClassEvent = (
  rateClass: string,
  trigger = EVENT_TRIGGER_PARAM.userInteraction
) => {
  gtmTrack(gtmEvents.RETAIL_SELECT_RATE_CLASS, { rate_class: rateClass, trigger });
};

export const triggerRetailSelectZoneEvent = (
  zone: string,
  trigger = EVENT_TRIGGER_PARAM.userInteraction
) => {
  gtmTrack(gtmEvents.RETAIL_SELECT_ZONE, { zone: zone, trigger });
};

export const triggerRetailSelectStateEvent = (
  state: string,
  trigger = EVENT_TRIGGER_PARAM.userInteraction
) => {
  gtmTrack(gtmEvents.RETAIL_SELECT_STATE, { state: state, trigger });
};

export const triggerRetailEnterAnnualUsageEvent = (
  annualUsage: string,
  unit: string,
  trigger = EVENT_TRIGGER_PARAM.userInteraction
) => {
  gtmTrack(gtmEvents.RETAIL_ENTER_ANNUAL_USAGE, {
    annual_usage: annualUsage,
    unit: unit.toUpperCase(),
    trigger,
  });
};

export const triggerRetailSearchZoneEvent = () => {
  gtmTrack(gtmEvents.RETAIL_SEARCH_ZONE, {});
};

export const triggerRetailSearchRateClass = () => {
  gtmTrack(gtmEvents.RETAIL_SEARCH_RATE_CLASS, {});
};

export const triggerRetailSearchUtility = () => {
  gtmTrack(gtmEvents.RETAIL_SEARCH_UTILITY, {});
};

export const triggerRetailGetQuoteEvent = (
  zipCode: string,
  customerType: string,
  commodity: string
) => {
  gtmTrack(gtmEvents.RETAIL_GET_QUOTE, {
    zip_code: Number(zipCode),
    customer_type: customerType,
    commodity: commodity,
  });
};

export const triggerRetailFocusLeadEvent = () => {
  gtmTrack(gtmEvents.RETAIL_FOCUS_LEAD, {});
};

export const triggerRetailSubmitLeadEvent = () => {
  gtmTrack(gtmEvents.RETAIL_SUBMIT_LEAD, {});
};

export const triggerRetailPopupCustomQuoteEvent = () => {
  gtmTrack(gtmEvents.RETAIL_POPUP_CUSTOM_QUOTE, {});
};

export const triggerRetailGetCustomQuoteEvent = (params: Record<string, any>) => {
  gtmTrack(gtmEvents.RETAIL_GET_CUSTOM_QUOTE, createRetailViewPlansParams(params));
};

export const triggerRetailSubmitStayUpdateEvent = () => {
  gtmTrack(gtmEvents.RETAIL_SUBMIT_STAY_UPDATE, {});
};

export const triggerRetailViewPlansEvent = (
  params: Record<string, any>,
  trigger = EVENT_TRIGGER_PARAM.userInteraction
) => {
  gtmTrack(gtmEvents.RETAIL_VIEW_PLANS, { ...createRetailViewPlansParams(params), trigger });
};

export const triggerRetailSelectPageEvent = (selectPage: number) => {
  gtmTrack(gtmEvents.RETAIL_SELECT_PAGE, { select_page: selectPage });
};

export const triggerRetailSortByEvent = (sort: string) => {
  gtmTrack(gtmEvents.RETAIL_SORT_BY, { sort_by: sort });
};

export const triggerRetailChangeStartDateEvent = (startData: string) => {
  gtmTrack(gtmEvents.RETAIL_CHANGE_START_DATE, { start_date: startData });
};

export const triggerRetailSelectTermEvent = (term: string) => {
  gtmTrack(gtmEvents.RETAIL_SELECT_TERM, { term: term });
};

export const triggerRetailViewPlanEvent = (plan: Plan) => {
  gtmTrack(gtmEvents.RETAIL_VIEW_PLAN, createPlanParams(plan));
};

export const triggerRetailSelectPlanEvent = (plan: Plan) => {
  gtmTrack(gtmEvents.RETAIL_SELECT_PLAN, createPlanParams(plan));
};

export const triggerRetailSelectBestPlanEvent = (plan: Plan) => {
  gtmTrack(gtmEvents.RETAIL_SELECT_BEST_PLAN, createPlanParams(plan));
};

export const triggerRetailBeginCheckoutEvent = () => {
  gtmTrack(gtmEvents.RETAIL_BEGIN_CHECKOUT, {});
};

export const triggerRetailAddUtilityAccountInfoEvent = () => {
  gtmTrack(gtmEvents.RETAIL_ADD_UTILITY_ACCOUNT_INFO, {});
};

export const triggerRetailAddMailingInfoEvent = () => {
  gtmTrack(gtmEvents.RETAIL_ADD_MAILING_INFO, {});
};

export const triggerRetailPlaceRetailEnergyOrderEvent = () => {
  gtmTrack(gtmEvents.RETAIL_PLACE_RETAIL_ENERGY_ORDER, {});
};

export const triggerLedProjectBeginCreation = () => {
  gtmTrack(gtmEvents.LED_PROJECT_BEGIN_CREATION, {});
};

export const triggerLedProjectCreation = () => {
  gtmTrack(gtmEvents.LED_PROJECT_CREATION, {});
};

export const triggerLedProjectContinue = () => {
  gtmTrack(gtmEvents.LED_PROJECT_CONTINUE, {});
};

export const triggerLedAuditStartEvent = () => {
  gtmTrack(gtmEvents.LED_AUDIT_START, {});
};

export const triggerLedAuditBeginAreaCreation = () => {
  gtmTrack(gtmEvents.LED_AUDIT_BEGIN_AREA_CREATION, {});
};

export const triggerLedAuditBeginElectricityRateCalculatorEvent = () => {
  gtmTrack(gtmEvents.LED_AUDIT_BEGIN_ELECTRICITY_RATE_CALCULATOR, {});
};

export const triggerLedAuditConfirmElectricityRateCalculatorEvent = () => {
  gtmTrack(gtmEvents.LED_AUDIT_CONFIRM_ELECTRICITY_RATE_CALCULATOR, {});
};

export const triggerLedLightingScheduleBeginCreation = () => {
  gtmTrack(gtmEvents.LED_LIGHTING_SCHEDULE_BEGIN_CREATION, {});
};

export const triggerLedLightingScheduleCreation = () => {
  gtmTrack(gtmEvents.LED_LIGHTING_SCHEDULE_CREATE, {});
};

export const triggerLedAuditSelectLightingSchedule = () => {
  gtmTrack(gtmEvents.LED_AUDIT_SELECT_LIGHTING_SCHEDULE, {});
};

export const triggerLedAuditCreateAreaEvent = () => {
  gtmTrack(gtmEvents.LED_AUDIT_CREATE_AREA, {});
};

export const triggerLedAuditSelectAreaEvent = () => {
  gtmTrack(gtmEvents.LED_AUDIT_SELECT_AREA, {});
};

export const triggerLedAuditBeginLineItemCreation = () => {
  gtmTrack(gtmEvents.LED_AUDIT_BEGIN_LINE_ITEM_CREATION, {});
};

export const triggerLedFixtureIdentificationBeginEvent = () => {
  gtmTrack(gtmEvents.LED_FIXTURE_IDENTIFICATION_BEGIN, {});
};

export const triggerLedFixtureUploadPictureEvent = () => {
  gtmTrack(gtmEvents.LED_FIXTURE_UPLOAD_PICTURE, {});
};

export const triggerLedFixtureSelectMountingEvent = (mounting: string, location: string) => {
  gtmTrack(gtmEvents.LED_FIXTURE_SELECT_MOUNTING, { mounting, location });
};

export const triggerLedFixtureSelectCategoryEvent = (category: string, subcategory: string) => {
  gtmTrack(
    gtmEvents.LED_FIXTURE_SELECT_CATEGORY,
    subcategory ? { category, subcategory } : { category }
  );
};

export const triggerLedSearchLampEvent = (searchedLampCode: string) => {
  gtmTrack(gtmEvents.LED_FIXTURE_SEARCH_LAMP, {
    searched_lamp_code: searchedLampCode,
  });
};

export const triggerLedFixtureSearchNoLampsEvent = (searchedLampCode: string) => {
  gtmTrack(gtmEvents.LED_FIXTURE_SEARCH_NO_LAMPS, { searched_lamp_code: searchedLampCode });
};

export const triggerLedConfirmLampEvent = (lampCode: string) => {
  gtmTrack(gtmEvents.LED_FIXTURE_CONFIRM_LAMP, { lamp_code: lampCode });
};

export const triggerLedFixtureConfirmNumberOfLampsEvent = (numberOfLamps: number) => {
  gtmTrack(gtmEvents.LED_FIXTURE_CONFIRM_NUMBER_OF_LAMPS, {
    number_of_lamps: numberOfLamps,
  });
};

export const triggerLedFixtureConfirmIdentifiedFixtureEvent = (params: {
  mounting: string;
  location: string;
  category: string;
  subcategory: string;
  lamp_code: string;
  number_of_Lamps: string;
}) => {
  gtmTrack(gtmEvents.LED_FIXTURE_CONFIRM_IDENTIFIED_FIXTURE, params);
};

export const triggerLedAuditSelectIdentifiedFixtureEvent = () => {
  gtmTrack(gtmEvents.LED_AUDIT_SELECT_IDENTIFIED_FIXTURE, {});
};

export const triggerLedAuditCreateLineItemEvent = () => {
  gtmTrack(gtmEvents.LED_AUDIT_CREATE_LINE_ITEM, {});
};

export const triggerLedAuditChangeLineItemQuantityEvent = () => {
  gtmTrack(gtmEvents.LED_AUDIT_CHANGE_LINE_ITEM_QUANTITY, {});
};

export const triggerLedAuditRemoveLineItemEvent = () => {
  gtmTrack(gtmEvents.LED_AUDIT_REMOVE_LINE_ITEM, {});
};

export const triggerLedAuditRemoveAreaEvent = () => {
  gtmTrack(gtmEvents.LED_AUDIT_REMOVE_AREA, {});
};

export const triggerLedAuditViewSummaryEvent = () => {
  gtmTrack(gtmEvents.LED_AUDIT_VIEW_SUMMARY, {});
};

export const triggerLedCompleteAuditEvent = () => {
  gtmTrack(gtmEvents.LED_COMPLETE_AUDIT, {});
};

export const triggerLedSpecificationGetRecommendationsEvent = () => {
  gtmTrack(gtmEvents.LED_SPECIFICATION_GET_RECOMMENDATIONS, {});
};

export const triggerLedSpecificationSelectExistingItemEvent = () => {
  gtmTrack(gtmEvents.LED_SPECIFICATION_SELECT_EXISTING_ITEM, {});
};

export const triggerLedSpecificationSelectAreaEvent = () => {
  gtmTrack(gtmEvents.LED_SPECIFICATION_SELECT_AREA, {});
};

export const triggerLedSpecificationViewRecommendationsEvent = (props: {
  location: string;
  category: string;
  number_of_lamps: number;
  length: number;
  voltage: number;
  cct: number;
  wattage: number;
  luminous_flux: number;
  width: number;
}) => {
  gtmTrack(gtmEvents.LED_SPECIFICATION_VIEW_RECOMMENDATIONS, props);
};

export const triggerLedSpecificationViewNoRecommendationsEvent = (props: {
  location: string;
  category: string;
  number_of_lamps: number;
  length: number;
  voltage: number;
  cct: number;
  wattage: number;
  luminous_flux: number;
  width: number;
}) => {
  gtmTrack(gtmEvents.LED_SPECIFICATION_VIEW_NO_RECOMMENDATIONS, props);
};

export const triggerLedSpecificationAddReplacementEvent = (props: {
  sku: string;
  index: number;
  brand: string;
}) => {
  gtmTrack(gtmEvents.LED_SPECIFICATION_ADD_REPLACEMENT, props);
};

export const triggerLedSpecificationChangeReplQuantityEvent = () => {
  gtmTrack(gtmEvents.LED_SPECIFICATION_CHANGE_REPL_QUANTITY, {});
};

export const triggerLedSpecificationRemoveReplacementEvent = () => {
  gtmTrack(gtmEvents.LED_SPECIFICATION_REMOVE_REPLACEMENT, {});
};

export const triggerLedSpecificationViewReplacementsEvent = () => {
  gtmTrack(gtmEvents.LED_SPECIFICATION_VIEW_REPLACEMENTS, {});
};

export const triggerLedLightingRetrofitBeginCheckoutEvent = () => {
  gtmTrack(gtmEvents.LED_LIGHTING_RETROFIT_BEGIN_CHECKOUT, {});
};

export const triggerLedLightingRetrofitAddCustomerInfoEvent = () => {
  gtmTrack(gtmEvents.LED_LIGHTING_RETROFIT_ADD_CUSTOMER_INFO, {});
};

export const triggerLedLightingRetrofitAddShippingInfoEvent = () => {
  gtmTrack(gtmEvents.LED_LIGHTING_RETROFIT_ADD_SHIPPING_INFO, {});
};

export const triggerLedLightingRetrofitAddBillingInfoEvent = () => {
  gtmTrack(gtmEvents.LED_LIGHTING_RETROFIT_ADD_BILLING_INFO, {});
};

export const triggerLedLightingRetrofitOrderEvent = () => {
  gtmTrack(gtmEvents.LED_LIGHTING_RETROFIT_ORDER, {});
};

export const triggerCameraOpenEvent = () => {
  gtmTrack(gtmEvents.SCAN_BILL_CAMERA_OPEN, {});
};

export const triggerCameraTakePictureEvent = () => {
  gtmTrack(gtmEvents.SCAN_BILL_CAMERA_TAKE_PICTURE, {});
};

export const triggerCameraCloseEvent = () => {
  gtmTrack(gtmEvents.SCAN_BILL_CAMERA_CLOSE, {});
};

export const triggerCameraRemovePictureEvent = () => {
  gtmTrack(gtmEvents.SCAN_BILL_CAMERA_REMOVE_PICTURE, {});
};

export const triggerUploadStartEvent = () => {
  gtmTrack(gtmEvents.SCAN_BILL_UPLOAD_START, {});
};

export const triggerUploadChooseFilesEvent = () => {
  gtmTrack(gtmEvents.SCAN_BILL_UPLOAD_CHOOSE_FILES, {});
};

export const triggerUploadRemoveFileEvent = () => {
  gtmTrack(gtmEvents.SCAN_BILL_UPLOAD_REMOVE_FILE, {});
};

export const triggerUploadEndEvent = () => {
  gtmTrack(gtmEvents.SCAN_BILL_UPLOAD_END, {});
};

export const triggerScrollUpEvent = () => {
  gtmTrack(gtmEvents.SCAN_BILL_SCROLL_UP, {});
};

export const triggerBackEvent = () => {
  gtmTrack(gtmEvents.SCAN_BILL_BACK, {});
};

export const triggerSubmitEvent = () => {
  gtmTrack(gtmEvents.SCAN_BILL_SUBMIT, {});
};

