import { HttpClient } from 'services/http';
import { HttpClientInterface } from 'services/http/types';
import { createCmsApiBaseUrl } from 'config/cms';

class CmsClient extends HttpClient implements HttpClientInterface {
  createEndpoint(url: string): string {
    return `${createCmsApiBaseUrl()}${url}`;
  }
}

export default CmsClient;
