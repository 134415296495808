import { HttpClientInterface } from 'services/http/types';
import { FILES_ENDPOINT } from 'config/files/endpoints';
import { FilesServiceInterface } from 'services/file/types';
import { AxiosResponse } from 'axios';

class FileService implements FilesServiceInterface {
  constructor(private readonly client: HttpClientInterface) {}

  async createFileUpload(fileUpload: FormData): Promise<AxiosResponse> {
    // @ts-ignore
    return await this.client.upload<void>(FILES_ENDPOINT, fileUpload);
  }
}

export default FileService;
