import { HttpClientInterface } from 'services/http/types';
import {
  DeliveryServiceInterface,
  State,
  StateResponse,
  Utility,
  ZipCodeResponse,
} from 'services/delivery/types';
import {
  DELIVERY_STATES_ENDPOINT,
  DELIVERY_UTILITIES_BY_STATE_ENDPOINT,
  DELIVERY_UTILITIES_BY_ZIP_CODE_ENDPOINT,
  DELIVERY_UTILITY_BY_ID_ENDPOINT,
  DELIVERY_ZIPCODES_BY_ID_ENDPOINT,
} from 'config/delivery/endpoints';

class DeliveryService implements DeliveryServiceInterface {
  private deregulatedStatesCache?: State[];
  private allStatesCache?: State[];

  constructor(private readonly client: HttpClientInterface) {}

  async getDeregulatedStates(query?: string): Promise<State[]> {
    if (!this.deregulatedStatesCache) {
      const { data } = await this.client.get<StateResponse[]>(DELIVERY_STATES_ENDPOINT, {
        deregulated: 'true',
      });
      this.deregulatedStatesCache = DeliveryService.normalizeStates(await data);
    }
    let states = [...this.deregulatedStatesCache];

    if (query) {
      const queryLowerCase = query.toLowerCase();
      states = states.filter(
        (state) =>
          state.stateName.toLowerCase().includes(queryLowerCase) ||
          state.state.toLowerCase() === queryLowerCase
      );
    }

    return states;
  }

  async getAllStates(query?: string): Promise<State[]> {
    if (!this.allStatesCache) {
      const { data } = await this.client.get<StateResponse[]>(DELIVERY_STATES_ENDPOINT);
      this.allStatesCache = DeliveryService.normalizeStates(await data);
    }
    let states = [...this.allStatesCache];

    if (query) {
      const queryLowerCase = query.toLowerCase();
      states = states.filter(
        (state) =>
          state.stateName.toLowerCase().includes(queryLowerCase) ||
          state.state.toLowerCase() === queryLowerCase
      );
    }

    return states;
  }

  async getZipCode(zipCode: string): Promise<ZipCodeResponse> {
    const { data } = await this.client.get<ZipCodeResponse>(
      DELIVERY_ZIPCODES_BY_ID_ENDPOINT(zipCode)
    );
    return data;
  }

  async getUtilityByState(state: string): Promise<Utility[]> {
    const { data } = await this.client.get<Utility[]>(DELIVERY_UTILITIES_BY_STATE_ENDPOINT(state));

    return data;
  }

  async getUtilityByZipCode(zipCode: string): Promise<Utility[]> {
    const { data } = await this.client.get<Utility[]>(
      DELIVERY_UTILITIES_BY_ZIP_CODE_ENDPOINT(zipCode)
    );

    return data;
  }

  async getUtilityById(utilityId: string): Promise<Utility> {
    const { data } = await this.client.get<Utility>(DELIVERY_UTILITY_BY_ID_ENDPOINT(utilityId));

    return data;
  }

  async getStateByCode(code: string): Promise<State | void> {
    if (!this.deregulatedStatesCache) {
      const { data } = await this.client.get<StateResponse[]>(DELIVERY_STATES_ENDPOINT, {
        deregulated: 'true',
      });
      this.deregulatedStatesCache = DeliveryService.normalizeStates(await data);
    }

    return this.deregulatedStatesCache.find((state) => state.state === code);
  }

  private static normalizeStates(data: StateResponse[]): State[] {
    return data.map((item) => ({
      stateName: item.name,
      state: item.usps,
    }));
  }
}

export default DeliveryService;
