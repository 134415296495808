import { DiContainer } from 'utils/di';
import { HttpClient } from './http';
import { HttpClientInterface } from './http/types';
import { DeliveryServiceInterface } from 'services/delivery/types';
import DeliveryService from 'services/delivery/DeliveryService';
import { ServiceContainerInterface } from 'services/types';
import { QuotesServiceInterface } from 'services/quotes/types';
import QuotesService from 'services/quotes/QuotesService';
import { AccountsServiceInterface } from 'services/accounts/types';
import AccountsService from 'services/accounts/AccountsService';
import { SessionInterface } from 'utils/services/types';
import LocalStorageSession from 'utils/services/LocalStorageSession';
import { CommercialSessionInterface } from 'services/retail/commercial/types';
import CommercialSession from 'services/retail/commercial/CommercialSession';
import { ResidentialSessionInterface } from 'services/retail/residential/types';
import ResidentialSession from 'services/retail/residential/ResidentialSession';
import { OrdersServiceInterface } from 'services/orders/types';
import OrdersService from 'services/orders/OrdersService';
import { FilesServiceInterface } from 'services/file/types';
import FileService from 'services/file/FileService';
import { CmsServiceInterface } from 'services/cms/types';
import { CmsClient, CmsService } from 'services/cms';
import AuditService from 'services/audit/AuditService';
import { AuditServiceInterface } from 'services/audit/types';
import { ProjectsServiceInterface } from 'services/projects/types';
import ProjectsService from 'services/projects/ProjectsService';
import { LedSessionInterface, OrderSessionInterface } from 'services/led/types';
import LedSession from 'services/led/LedSession';
import { SpecificationService } from 'services/specification';
import { SpecificationServiceInterface } from 'services/specification/types';
import { FixtureIdentificationServiceInterface } from 'services/fixtureIdentification/types';
import FixtureIdentificationService from 'services/fixtureIdentification/FixtureIdentificationService';
import { ProductsServiceInterface } from 'services/products/types';
import ProductsService from 'services/products/ProductsService';
import CatalogCartService from 'services/catalog/CatalogCartService';
import { CATALOG_SESSION_KEY } from 'config/products';
import { LED_SESSION_KEY } from 'config/led';
import OrderSession from 'services/led/OrderSession';

class ServiceContainer extends DiContainer implements ServiceContainerInterface {
  get session(): SessionInterface {
    return this.getInstance('session', () => new LocalStorageSession(false));
  }

  get deliveryService(): DeliveryServiceInterface {
    return this.getInstance('deliveryService', () => new DeliveryService(this.httpClient));
  }

  get quotesService(): QuotesServiceInterface {
    return this.getInstance('quotesService', () => new QuotesService(this.httpClient));
  }

  get accountsService(): AccountsServiceInterface {
    return this.getInstance('accountsService', () => new AccountsService(this.httpClient));
  }

  get commercialSession(): CommercialSessionInterface {
    return this.getInstance('commercialSession', () => new CommercialSession(this.session));
  }

  get residentialSession(): ResidentialSessionInterface {
    return this.getInstance('residentialSession', () => new ResidentialSession(this.session));
  }

  get ordersService(): OrdersServiceInterface {
    return this.getInstance('ordersService', () => new OrdersService(this.httpClient));
  }

  get fileService(): FilesServiceInterface {
    return this.getInstance('fileService', () => new FileService(this.httpClient));
  }

  get cmsService(): CmsServiceInterface {
    return this.getInstance('cmsService', () => new CmsService(this.cmsClient));
  }

  private get httpClient(): HttpClientInterface {
    return this.getInstance('httpClient', () => new HttpClient());
  }

  private get cmsClient(): HttpClientInterface {
    return this.getInstance('cmsClient', () => new CmsClient());
  }

  get ledSession(): LedSessionInterface {
    return this.getInstance(
      'ledSession',
      () => new LedSession(LED_SESSION_KEY, new LocalStorageSession(true))
    );
  }

  get auditService(): AuditServiceInterface {
    return this.getInstance('auditService', () => new AuditService(this.httpClient));
  }

  get specificationService(): SpecificationServiceInterface {
    return this.getInstance(
      'specificationService',
      () => new SpecificationService(this.httpClient)
    );
  }

  get projectsService(): ProjectsServiceInterface {
    return this.getInstance('projectsService', () => new ProjectsService(this.httpClient));
  }

  get fixtureIdentificationService(): FixtureIdentificationServiceInterface {
    return this.getInstance(
      'fixtureIdentificationService',
      () => new FixtureIdentificationService(this.httpClient)
    );
  }

  get productsService(): ProductsServiceInterface {
    return this.getInstance('productsService', () => new ProductsService(this.httpClient));
  }

  get catalogSession(): OrderSessionInterface {
    return this.getInstance(
      'catalogSession',
      () => new OrderSession(CATALOG_SESSION_KEY, new LocalStorageSession(true))
    );
  }

  get catalogCartService() {
    return this.getInstance(
      'catalogCartService',
      () => new CatalogCartService(CATALOG_SESSION_KEY, new LocalStorageSession(true))
    );
  }
}

export default ServiceContainer;
