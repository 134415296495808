import { AnnualUsage, AnnualUsageUnit } from 'services/orders/types';
import { HumanViewUnit, HumanViewUnitPlural } from 'utils/data/types';
import { State } from 'services/delivery/types';

export const getUnitSign = (unit: string, plural = false) => {
  const UnitEnum = plural ? HumanViewUnitPlural : HumanViewUnit;

  switch (unit) {
    case AnnualUsageUnit.KWH:
      return UnitEnum.KWH;
    case AnnualUsageUnit.THERM:
      return UnitEnum.THERM;
    case AnnualUsageUnit.CCF:
      return UnitEnum.CCF;
    default:
      return '';
  }
};

export const unitByAnnualUsage = (annualUsage?: Partial<AnnualUsage>, plural = false) =>
  annualUsage ? getUnitSign(annualUsage.unit, plural) : '';

const states: State[] = [
  { stateName: 'Alabama', state: 'AL' },
  { stateName: 'Alaska', state: 'AK' },
  { stateName: 'American Samoa', state: 'AS' },
  { stateName: 'Arizona', state: 'AZ' },
  { stateName: 'Arkansas', state: 'AR' },
  { stateName: 'California', state: 'CA' },
  { stateName: 'Colorado', state: 'CO' },
  { stateName: 'Connecticut', state: 'CT' },
  { stateName: 'Delaware', state: 'DE' },
  { stateName: 'District Of Columbia', state: 'DC' },
  { stateName: 'Federated States Of Micronesia', state: 'FM' },
  { stateName: 'Florida', state: 'FL' },
  { stateName: 'Georgia', state: 'GA' },
  { stateName: 'Guam', state: 'GU' },
  { stateName: 'Hawaii', state: 'HI' },
  { stateName: 'Idaho', state: 'ID' },
  { stateName: 'Illinois', state: 'IL' },
  { stateName: 'Indiana', state: 'IN' },
  { stateName: 'Iowa', state: 'IA' },
  { stateName: 'Kansas', state: 'KS' },
  { stateName: 'Kentucky', state: 'KY' },
  { stateName: 'Louisiana', state: 'LA' },
  { stateName: 'Maine', state: 'ME' },
  { stateName: 'Marshall Islands', state: 'MH' },
  { stateName: 'Maryland', state: 'MD' },
  { stateName: 'Massachusetts', state: 'MA' },
  { stateName: 'Michigan', state: 'MI' },
  { stateName: 'Minnesota', state: 'MN' },
  { stateName: 'Mississippi', state: 'MS' },
  { stateName: 'Missouri', state: 'MO' },
  { stateName: 'Montana', state: 'MT' },
  { stateName: 'Nebraska', state: 'NE' },
  { stateName: 'Nevada', state: 'NV' },
  { stateName: 'New Hampshire', state: 'NH' },
  { stateName: 'New Jersey', state: 'NJ' },
  { stateName: 'New Mexico', state: 'NM' },
  { stateName: 'New York', state: 'NY' },
  { stateName: 'North Carolina', state: 'NC' },
  { stateName: 'North Dakota', state: 'ND' },
  { stateName: 'Northern Mariana Islands', state: 'MP' },
  { stateName: 'Ohio', state: 'OH' },
  { stateName: 'Oklahoma', state: 'OK' },
  { stateName: 'Oregon', state: 'OR' },
  { stateName: 'Palau', state: 'PW' },
  { stateName: 'Pennsylvania', state: 'PA' },
  { stateName: 'Puerto Rico', state: 'PR' },
  { stateName: 'Rhode Island', state: 'RI' },
  { stateName: 'South Carolina', state: 'SC' },
  { stateName: 'South Dakota', state: 'SD' },
  { stateName: 'Tennessee', state: 'TN' },
  { stateName: 'Texas', state: 'TX' },
  { stateName: 'Utah', state: 'UT' },
  { stateName: 'Vermont', state: 'VT' },
  { stateName: 'Virgin Islands', state: 'VI' },
  { stateName: 'Virginia', state: 'VA' },
  { stateName: 'Washington', state: 'WA' },
  { stateName: 'West Virginia', state: 'WV' },
  { stateName: 'Wisconsin', state: 'WI' },
  { stateName: 'Wyoming', state: 'WY' },
];

export const getStateByShort = (short: string): State =>
  states.find((item) => item.state === short);
