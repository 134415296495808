import { HttpClientInterface } from 'services/http/types';
import { extractId, serializeParams } from 'utils/services';
import {
  CreateReplacementRequest,
  ProjectSpecification,
  Specification,
  SpecificationServiceInterface,
  SpecificationSummary,
} from 'services/specification/types';
import {
  SPECIFICATION_BY_ID_ENDPOINT,
  SPECIFICATION_COMPLETE_ENDPOINT,
  SPECIFICATION_REPLACEMENT_BY_ID_ENDPOINT,
  SPECIFICATION_REPLACEMENT_QUANTITY_ENDPOINT,
  SPECIFICATION_REPLACEMENTS_ENDPOINT,
  SPECIFICATION_SUMMARY_ENDPOINT,
  SPECIFICATIONS_ENDPOINT,
} from 'config/specifications/endpoints';

class SpecificationService implements SpecificationServiceInterface {
  constructor(private readonly client: HttpClientInterface) {}

  async addSpecification(auditId: string): Promise<string> {
    const {
      headers: { location },
    } = await this.client.post(SPECIFICATIONS_ENDPOINT, { auditId }, serializeParams);
    return extractId(location);
  }

  async getSpecificationSummary(specificationId: string): Promise<SpecificationSummary> {
    const { data } = await this.client.get<SpecificationSummary>(
      SPECIFICATION_SUMMARY_ENDPOINT(specificationId)
    );
    return data;
  }

  async getSpecifications(projectId: string): Promise<ProjectSpecification[]> {
    const { data } = await this.client.get<ProjectSpecification[]>(SPECIFICATIONS_ENDPOINT, {
      projectId,
    });
    return data;
  }

  async getSpecification(specificationId: string): Promise<Specification> {
    const { data } = await this.client.get<Specification>(
      SPECIFICATION_BY_ID_ENDPOINT(specificationId)
    );
    return data;
  }

  async addReplacement(
    specificationId: string,
    replacement: CreateReplacementRequest
  ): Promise<string> {
    const {
      headers: { location },
    } = await this.client.post(
      SPECIFICATION_REPLACEMENTS_ENDPOINT(specificationId),
      replacement,
      serializeParams
    );
    return extractId(location);
  }

  async deleteReplacement(
    specificationId: string,
    replacementId: string
  ): Promise<{ status: number }> {
    const { status } = await this.client.delete(
      SPECIFICATION_REPLACEMENT_BY_ID_ENDPOINT(specificationId, replacementId)
    );
    return { status };
  }

  async changeReplacementQuantity(
    specificationId: string,
    replacementId: string,
    quantity: number
  ): Promise<void> {
    await this.client.put(
      SPECIFICATION_REPLACEMENT_QUANTITY_ENDPOINT(specificationId, replacementId),
      { quantity },
      serializeParams
    );
  }

  async completeSpecification(specificationId: string): Promise<void> {
    await this.client.put(SPECIFICATION_COMPLETE_ENDPOINT(specificationId));
  }
}

export default SpecificationService;
