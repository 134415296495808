export enum HumanViewUnit {
  KWH = 'kWh',
  THERM = 'therm',
  CCF = 'ccf',
}

export enum HumanViewUnitPlural {
  KWH = 'kWh',
  THERM = 'therms',
  CCF = 'ccf',
}
