import { HttpClientInterface } from 'services/http/types';
import { BEST_PLANS_ENDPOINT, BEST_PRICE_ENDPOINT, PLANS_ENDPOINT } from 'config/quotes/endpoints';
import {
  GetBestPlansRequest,
  GetBestPlansResponse,
  GetBestPriceRequest,
  GetPlansRequest,
  GetPlansResponse,
  Plan,
  QuotesServiceInterface,
} from 'services/quotes/types';
import { BROKER_ID, HEADER_PAGINATION_TOTAL_ELEMENTS } from 'config/services';
import {
  transformGetBestPlansRequest,
  transformGetBestPriceRequest,
  transformGetPlansRequest,
} from 'services/quotes/transformers';

class QuotesService implements QuotesServiceInterface {
  constructor(private readonly client: HttpClientInterface) {}

  async getPlans(request: GetPlansRequest): Promise<GetPlansResponse> {
    const apiRequest = transformGetPlansRequest({ ...request, brokerId: BROKER_ID });
    const { data, headers } = await this.client.get<Plan[]>(PLANS_ENDPOINT, apiRequest, /*{
      paramsSerializer: (params) => decodeURIComponent(new URLSearchParams(params).toString()),
    }*/);

    return { plans: await data, total: await headers[HEADER_PAGINATION_TOTAL_ELEMENTS] };
  }

  async getBestPlans(request: GetBestPlansRequest): Promise<GetBestPlansResponse> {
    const apiRequest = transformGetBestPlansRequest({ ...request, brokerId: BROKER_ID });
    const { data } = await this.client.get<Plan[]>(BEST_PLANS_ENDPOINT, apiRequest);
    const bestPlans = (await data).map((plan) => ({ ...plan, isTheBest: true }));

    return { bestPlans };
  }

  async getBestPrice(request: GetBestPriceRequest): Promise<Plan> {
    const apiRequest = transformGetBestPriceRequest({ ...request, brokerId: BROKER_ID });
    try {
      const { data } = await this.client.get<Plan>(BEST_PRICE_ENDPOINT, apiRequest, {
        paramsSerializer: (params) => decodeURIComponent(new URLSearchParams(params).toString()),
      });
      return data;
    } catch (e) {
      return null;
    }
  }

  async getPlan(planId: string): Promise<Plan> {
    try {
      const { data } = await this.client.get<Plan>(`${PLANS_ENDPOINT}/${planId}`);
      return data;
    } catch (e) {
      return null;
    }
  }
}

export default QuotesService;
