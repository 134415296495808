const FIXTURE_IDENTIFICATIONS = 'identified-fixtures';
const FIXTURES = 'fixtures';
const LAMPS = 'lamps';
const IDENTIFIED_FIXTURE_INSTALLATIONS = 'identified-fixture-installations';

export const FIXTURE_IDENTIFICATIONS_ENDPOINT = `/${FIXTURE_IDENTIFICATIONS}`;
export const FIXTURE_MOUNTINGS_ENDPOINT = `/${FIXTURES}/mountings`;
export const FIXTURE_CATEGORIES_ENDPOINT = `/${FIXTURES}/categories`;
export const FIXTURES_ENDPOINT = `/${FIXTURES}`;
export const FIXTURES_LAMPS_ENDPOINT = `/${LAMPS}`;
export const FIXTURES_IDENTIFIED_INSTALLATIONS_ENDPOINT = `/${IDENTIFIED_FIXTURE_INSTALLATIONS}`;
export const FIXTURE_IDENTIFICATIONS_GET_ENDPOINT = (identifiedFixtureId: string): string =>
  `/${FIXTURE_IDENTIFICATIONS}/${identifiedFixtureId}`;
