import { HttpClientInterface } from 'services/http/types';
import { AccountsServiceInterface, CreateResidentialRequest } from 'services/accounts/types';
import { ACCOUNTS_ENDPOINT } from 'config/accounts/endpoints';
import { extractId } from 'utils/services';

class AccountsService implements AccountsServiceInterface {
  constructor(private readonly client: HttpClientInterface) {}

  async createResidential(account: CreateResidentialRequest): Promise<string> {
    const {
      headers: { location },
    } = await this.client.post<void>(ACCOUNTS_ENDPOINT, account);

    if (!location) {
      throw new Error('Response does not have location header');
    }

    return extractId(location);
  }
}

export default AccountsService;
