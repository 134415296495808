export const headerEvChargingImage = require('assets/images/home/evCharging.webp');
export const headerLedServicesImage = require('assets/images/home/ledServices.webp');
export const headerRetailEnergyImage = require('assets/images/home/retailEnergy.webp');
export const sliderEvChargingImage = require('assets/images/home/sliderEvCharging.webp');
export const sliderLedServicesImage = require('assets/images/home/sliderLedServices.webp');
export const sliderRetailImage = require('assets/images/home/sliderRetail.webp');
// export const homeExploreEnergy5Image = require('assets/images/home/exploreEnergy5.webp');
export const retailBgImage = require('assets/images/home/retailBg.webp');
export const retailBgEffectImage = require('assets/images/home/retailBgEffect.webp');
export const ledBgImage = require('assets/images/home/ledBg.webp');
export const ledBgEffectImage = require('assets/images/home/ledBgEffect.webp');
export const evBgImage = require('assets/images/home/evBg.webp');
export const evBgEffectImage = require('assets/images/home/evBgEffect.webp');
export const exploreEnergy5 = require('assets/images/home/exploreEnergy5.webp');
export const retailEnergyBg = require('assets/images/home/retailEnergyBg.webp');
export const ledBg = require('assets/images/home/ledsBg.webp');
export const evChargingBg = require('assets/images/home/evChargingBg.webp');
export const solarBg = require('assets/images/home/solarBg.webp');
export const batteryBg = require('assets/images/home/batteryBg.webp');
export const hvacBg = require('assets/images/home/hvacBg.webp');
export const demandBg = require('assets/images/home/demandBg.webp');
export const gasBg = require('assets/images/home/gasBg.webp');
export const thermostatBg = require('assets/images/home/thermostatBg.webp');
export const waterBg = require('assets/images/home/waterBg.webp');
export const windowsDoorsBg = require('assets/images/home/windowsDoorsBg.webp');
export const electricianBg = require('assets/images/home/electricianBg.webp');
