import { LedSessionInterface } from './types';
import { CreateIdentifiedFixtureInstallationRequest } from 'services/fixtureIdentification/types';
import OrderSession from 'services/led/OrderSession';

class LedSession extends OrderSession implements LedSessionInterface {
  clearAll(): void {
    this.clear();
  }

  setProjectId = (projectId: string) => {
    this.write('projectId', projectId);
  };

  getProjectId = (): string | undefined => {
    return this.read('projectId');
  };

  hasProjectId = (): boolean => {
    return !!this.getProjectId();
  };

  setAuditId = (auditId: string) => {
    this.write('auditId', auditId);
  };

  getAuditId = (): string | undefined => {
    return this.read('auditId');
  };

  hasAuditId = (): boolean => {
    return !!this.getAuditId();
  };

  getFixtureIdentification(key = null, byDefault = null): any {
    const data = this.read('fixtureIdentification') || {};
    if (!key) {
      return data;
    }
    return data[key] || byDefault;
  }

  setFixtureIdentification(key = null, value = null) {
    const data = this.getFixtureIdentification();
    if (!key) {
      return this.write('fixtureIdentification', value);
    }
    this.write('fixtureIdentification', { ...data, [key]: value });
  }

  hasFixtureIdentification(key = null): boolean {
    if (!key) {
      return !!this.read('fixtureIdentification');
    }
    return !!this.getFixtureIdentification(key);
  }

  clearFixtureIdentification(key = null) {
    if (!key) {
      return this.clear('fixtureIdentification');
    }
    const data = this.getFixtureIdentification();
    const updated = Object.keys(data).reduce(
      (obj, k) => (k === key ? obj : { ...obj, [k]: data[k] }),
      {}
    );
    this.write('fixtureIdentification', updated);
  }

  getSpecificationId(): string | undefined {
    return this.read('specificationId');
  }

  hasSpecificationId(): boolean {
    return !!this.getSpecificationId();
  }

  setSpecificationId(specificationId: string) {
    this.write('specificationId', specificationId);
  }

  setEmail(email: string) {
    this.write('email', email);
  }

  getEmail(): string {
    return this.read('email');
  }

  clearIdentifiedFixtureInstallation() {
    this.clear('identifiedFixtureInstallation');
  }

  getIdentifiedFixtureInstallation(): CreateIdentifiedFixtureInstallationRequest {
    return this.read('identifiedFixtureInstallation');
  }

  setIdentifiedFixtureInstallation(value: CreateIdentifiedFixtureInstallationRequest) {
    this.write('identifiedFixtureInstallation', value);
  }

  setHasReplacements(value: boolean) {
    this.write('hasReplacements', value);
  }

  hasReplacements(): boolean {
    return this.read('hasReplacements');
  }
}

export default LedSession;
