export const SPECIFICATIONS_ENDPOINT = '/specifications';
export const SPECIFICATION_BY_ID_ENDPOINT = (specificationId: string) =>
  `${SPECIFICATIONS_ENDPOINT}/${specificationId}`;
export const SPECIFICATION_REPLACEMENTS_ENDPOINT = (specificationId: string) =>
  `${SPECIFICATION_BY_ID_ENDPOINT(specificationId)}/replacements`;
export const SPECIFICATION_REPLACEMENT_BY_ID_ENDPOINT = (
  specificationId: string,
  replacementId: string
) => `${SPECIFICATION_REPLACEMENTS_ENDPOINT(specificationId)}/${replacementId}`;
export const SPECIFICATION_REPLACEMENT_QUANTITY_ENDPOINT = (
  specificationId: string,
  replacementId: string
) => `${SPECIFICATION_REPLACEMENT_BY_ID_ENDPOINT(specificationId, replacementId)}/quantity`;
export const SPECIFICATION_SUMMARY_ENDPOINT = (specificationId: string) =>
  `${SPECIFICATION_BY_ID_ENDPOINT(specificationId)}/summary`;
export const SPECIFICATION_COMPLETE_ENDPOINT = (specificationId: string) =>
  `${SPECIFICATION_BY_ID_ENDPOINT(specificationId)}/complete`;
