import { ENV } from 'evcharging/app';
import { Plan } from 'services/quotes/types';
import { unitByAnnualUsage } from 'utils/data';

export const extractId = (url: string): string => url.split('/')[url.split('/').length - 1];
export const fileById = (id: string): string => `${ENV.API_BASE_URL}/files/${id}`;
export const getAbsoluteUrl = (function () {
  let a;
  return function (url) {
    if (!a) a = document.createElement('a');
    a.href = url;

    return a.href;
  };
})();

export const humanFileSize = (bytes, dp = 1) => {
  const thresh = 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
};

export const planAnnualUsageString = (plan: Plan): string => {
  const { annualUsage } = plan;
  const { minimum, maximum, unit } = annualUsage;
  const min = minimum.toLocaleString('en-US');
  const max = maximum.toLocaleString('en-US');
  const unitString = unitByAnnualUsage({ unit }, true);
  return `${min} .. ${max} ${unitString}`;
};

export const serializeParams = {
  paramsSerializer: (params: { [key: string]: string }) =>
    decodeURIComponent(new URLSearchParams(params).toString()),
};
