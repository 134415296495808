import {
  FormErrors,
  FormFieldFilters,
  FormFieldValidators,
  FormFilters,
  FormValidatorResult,
  FormValidators,
  FormValues,
  TErrors,
} from './types';

export const filterValues = <Values>(valuesFilters: FormFilters, values: Values): Values => {
  const filteredValues: FormValues = { ...values };

  for (const [field, filters] of Object.entries(valuesFilters)) {
    filteredValues[field] = filterValue(filters, filteredValues[field]);
  }

  return filteredValues as Values;
};

export const filterValue = <V = any>(valueFilters: FormFieldFilters = [], value: V): any => {
  const filters = Array.isArray(valueFilters) ? valueFilters : [valueFilters];
  return filters.reduce((next, filter) => filter(next), value);
};

export const validateValues = <Values, Errors extends TErrors<Values> = TErrors<Values>>(
  valuesValidators: FormValidators<Values>,
  values: Values
): [boolean, Errors] => {
  const errors: FormErrors = {};

  for (const [field, validators] of Object.entries(valuesValidators)) {
    const [isValid, error] = validateValue<Values>(
      validators,
      (values as FormErrors)[field],
      values as Values
    );

    if (!isValid) {
      errors[field] = error;
    }
  }

  return [!Object.entries(errors).length, errors as Errors];
};

export const validateValue = <Values, V = any>(
  valueValidators: FormFieldValidators<Values>,
  value: V,
  values?: Values
): FormValidatorResult => {
  const validators = Array.isArray(valueValidators) ? valueValidators : [valueValidators];

  for (let i = 0; i < validators.length; i++) {
    const [isValid, error] = validators[i](value, values);
    if (!isValid) {
      return [false, error];
    }
  }

  return [true, ''];
};

export const validateArray = <Values, Errors extends TErrors<Values> = TErrors<Values>>(
  valuesValidators: FormValidators<Values>,
  arrayOfValues: Values[]
): [boolean, Errors[]] => {
  const arrayOfErrors: Errors[] = [];
  let isValidEveryValues = true;

  arrayOfValues.forEach((values: Values) => {
    const [isValid, errors] = validateValues<Values>(valuesValidators, values);
    arrayOfErrors.push(errors as Errors);
    if (!isValid) {
      isValidEveryValues = false;
    }
  });

  return [isValidEveryValues, arrayOfErrors];
};
