import { SessionInterface } from 'utils/services/types';

class RootBasedSession {
  constructor(private readonly rootKey: string, private readonly session: SessionInterface) {}

  protected clear(key = null) {
    if (!key) {
      return this.session.remove(this.rootKey);
    }
    const rootData = this.session.has(this.rootKey)
      ? JSON.parse(this.session.get(this.rootKey))
      : {};
    const updated = Object.keys(rootData).reduce(
      (obj, k) => (k === key ? obj : { ...obj, [k]: rootData[k] }),
      {}
    );
    this.session.set(this.rootKey, JSON.stringify(updated));
  }

  protected read = (key: string): any => this.session.read(this.rootKey, key);

  protected write = (key: string, data: any): any => this.session.write(this.rootKey, key, data);
}

export default RootBasedSession;
