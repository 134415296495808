import { SessionInterface } from 'utils/services/types';
import {
  CommercialCustomerInformation,
  CommercialSessionInterface,
  UploadedBill,
} from 'services/retail/commercial/types';
import { Plan } from 'services/quotes/types';
import {
  CommercialMailingBillingInformation,
  CommercialUtilityAccountInformation,
} from 'services/retail/commercial/types';
import { AnnualUsage } from 'services/orders/types';
import { BillSamples } from 'services/delivery/types';

const COMMERCIAL_SESSION_KEY = 'COMMERCIAL_SESSION';

class CommercialSession implements CommercialSessionInterface {
  constructor(private readonly session: SessionInterface) {}

  clear() {
    this.session.remove(COMMERCIAL_SESSION_KEY);
  }

  read = (key: string) => this.session.read(COMMERCIAL_SESSION_KEY, key);

  write = (key: string, data: any) => this.session.write(COMMERCIAL_SESSION_KEY, key, data);

  setSelectedPlan = (plan: Plan) => {
    this.write('selectedPlan', plan);
  };

  hasStarted(): boolean {
    return !!this.getSelectedPlan();
  }

  getSelectedPlan = (): Plan | undefined => {
    return this.read('selectedPlan');
  };

  setAnnualUsage = (annualUsage: AnnualUsage) => {
    this.write('annualUsage', annualUsage);
  };

  getAnnualUsage = (): AnnualUsage | undefined => {
    return this.read('annualUsage');
  };

  getBackUrl = (): string => {
    return this.read('backUrl');
  };

  getEffectiveDate = (): string => {
    return this.read('effectiveDate');
  };

  setEffectiveDate = (effectiveDate: string): void => {
    this.write('effectiveDate', effectiveDate);
  };

  setBackUrl = (backUrl: string): void => {
    this.write('backUrl', backUrl);
  };

  setCustomerInformation = (customerInformation: CommercialCustomerInformation) => {
    this.write('customerInformation', customerInformation);
  };

  getCustomerInformation = (): CommercialCustomerInformation => {
    return this.read('customerInformation');
  };

  setUtilityAccountInformation = (
    utilityAccountInformation: Partial<CommercialUtilityAccountInformation>[]
  ) => {
    this.write('utilityAccountInformation', utilityAccountInformation);
  };

  getUtilityAccountInformation = (): CommercialUtilityAccountInformation[] => {
    return this.read('utilityAccountInformation');
  };

  setMailingBillingInformation = (
    mailingBillingInformation: CommercialMailingBillingInformation
  ) => {
    this.write('mailingBillingInformation', mailingBillingInformation);
  };

  getMailingBillingInformation = (): CommercialMailingBillingInformation => {
    return this.read('mailingBillingInformation');
  };

  getUploadedBills = (): UploadedBill[] => {
    return this.read('uploadedBills');
  };

  setUploadedBills = (uploadedBills: UploadedBill[]): void => {
    this.write('uploadedBills', uploadedBills);
  };

  hasUploadedBills(): boolean {
    return !!this.getUploadedBills() && !!this.getUploadedBills().length;
  }

  getCheckedUtilityAccountIndex(): number {
    return this.read('checkedUtilityAccountIndex');
  }

  setCheckedUtilityAccountIndex(index: number): void {
    this.write('checkedUtilityAccountIndex', index);
  }

  setBillSamples = (billSamples: BillSamples) => {
    this.write('billSamples', billSamples);
  };

  getBillSamples = (): BillSamples => {
    return this.read('billSamples');
  };
}

export default CommercialSession;
