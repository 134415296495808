import RootBasedSession from 'utils/services/RootBasedSession';
import { ReplacementLineItem } from 'services/specification/types';
import { Product } from 'services/products/types';
import { CatalogCartServiceInterface } from 'services/catalog/types';

class CatalogCartService extends RootBasedSession implements CatalogCartServiceInterface {
  getCartItems = (): Promise<ReplacementLineItem[]> => {
    return Promise.resolve(this.read('cartItems') || []);
  };

  setCartItemQuantity = async (product: Product, quantity): Promise<void> => {
    let cartItems = await this.getCartItems();
    const cartItem = cartItems.find((lineItem) => lineItem.product.productId === product.productId);
    if (cartItem) {
      if (quantity === 0) {
        cartItems = cartItems.filter(
          (lineItem) => lineItem.product.productId !== product.productId
        );
      } else {
        cartItem.quantity = quantity;
      }
    } else {
      cartItems.push({ product, quantity });
    }
    this.write('cartItems', cartItems);
  };
}

export default CatalogCartService;
