import { HttpClientInterface } from 'services/http/types';
import { serializeParams } from 'utils/services';
import {
  CalculateShippingCostRequest,
  GetProductsRequest,
  Product,
  ProductsRequest,
  ProductsResponse,
  ProductsServiceInterface,
} from 'services/products/types';
import {
  PRODUCTS_ENDPOINT,
  RECOMMENDATIONS_ENDPOINT,
  SHIPPING_ADDRESS_ENDPOINT,
} from 'config/products/endpoints';
import { MoneyType } from 'services/types';

class ProductsService implements ProductsServiceInterface {
  constructor(private readonly client: HttpClientInterface) {}

  async getRecommendations(request: GetProductsRequest): Promise<Product[]> {
    const { data } = await this.client.post<Product[]>(
      RECOMMENDATIONS_ENDPOINT,
      request,
      serializeParams
    );
    return data;
  }

  async calculateShippingCost(request: CalculateShippingCostRequest): Promise<MoneyType> {
    const { data } = await this.client.post<{ shippingCost: MoneyType }>(
      SHIPPING_ADDRESS_ENDPOINT,
      request
    );
    return (await data).shippingCost;
  }

  async getProducts(request: ProductsRequest = {}): Promise<ProductsResponse> {
    const { data } = await this.client.get<ProductsResponse>(PRODUCTS_ENDPOINT, request, {
      paramsSerializer: (params) => decodeURIComponent(new URLSearchParams(params).toString()),
    });

    return data;
  }
}

export default ProductsService;
