import React, { FC, forwardRef } from 'react';
import { SvgIconProps } from '@material-ui/core';

import DarkLogoSvg from 'assets/svgs/nav/dark-logo.svg';
import DarkLogoMobileSvg from 'assets/svgs/nav/dark-logo-mobile.svg';
import LightLogoSvg from 'assets/svgs/nav/retail-logo.svg';
import BurgerMenuSvg from 'assets/svgs/nav/burger-menu.svg';
import NavCloseIconDarkSvg from 'assets/svgs/nav/nav-close-icon-black.svg';
import BurgerMenuDarkSvg from 'assets/svgs/nav/burger-menu-dark.svg';
import NavCloseIconSvg from 'assets/svgs/nav/nav-close-icon.svg';

export const DarkLogo: FC<SvgIconProps> = forwardRef((props, ref) => (
  <DarkLogoSvg ref={ref} {...props} />
));

export const DarkMobileLogo: FC<SvgIconProps> = forwardRef((props, ref) => (
  <DarkLogoMobileSvg ref={ref} {...props} />
));

export const LightLogo: FC<SvgIconProps> = forwardRef((props, ref) => (
  <LightLogoSvg ref={ref} {...props} />
));

export const BurgerMenu: FC<SvgIconProps> = forwardRef((props, ref) => (
  <BurgerMenuSvg ref={ref} {...props} />
));

export const NavCloseIconDark: FC<SvgIconProps> = forwardRef((props, ref) => (
  <NavCloseIconDarkSvg ref={ref} {...props} />
));

export const BurgerMenuDark: FC<SvgIconProps> = forwardRef((props, ref) => (
  <BurgerMenuDarkSvg ref={ref} {...props} />
));

export const NavCloseIcon: FC<SvgIconProps> = forwardRef((props, ref) => (
  <NavCloseIconSvg ref={ref} {...props} />
));
