import { AddressInterface, CustomerInformation } from 'services/orders/types';
import RootBasedSession from 'utils/services/RootBasedSession';
import { OrderSessionInterface } from 'services/led/types';

class OrderSession extends RootBasedSession implements OrderSessionInterface {
  clearAll(): void {
    this.clear();
  }

  getBillingAddress(): AddressInterface {
    return this.read('billingAddress');
  }

  getCustomerInformation(): CustomerInformation {
    return this.read('customerInformation');
  }

  getShippingAddress(): AddressInterface {
    return this.read('shippingAddress');
  }

  hasBillingAddress(): boolean {
    return !!this.getBillingAddress();
  }

  hasCustomerInformation(): boolean {
    return !!this.getCustomerInformation();
  }

  hasShippingAddress(): boolean {
    return !!this.getShippingAddress();
  }

  isIdenticalAddresses(): boolean {
    return !!this.read('isIdenticalAddresses');
  }

  setBillingAddress(value: AddressInterface): void {
    this.write('billingAddress', value);
  }

  setCustomerInformation(value: CustomerInformation): void {
    this.write('customerInformation', value);
  }

  setShippingAddress(value: AddressInterface): void {
    this.write('shippingAddress', value);
  }

  setIsIdenticalAddresses(value: boolean): void {
    this.write('isIdenticalAddresses', value);
  }

  getEmail(): string {
    return '';
  }
}

export default OrderSession;
