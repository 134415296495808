import { DiContainer } from 'utils/di/index';
import { ServiceContainerInterface } from 'services/types';

class StoreContainer extends DiContainer {
  constructor(protected readonly services: ServiceContainerInterface) {
    super();
  }

  initState = (initialState: unknown): void => {
    if (initialState) {
      for (const [storeName, storeInitialState] of Object.entries(initialState)) {
        this[storeName].initState(storeInitialState);
      }
    }
  };
}

export default StoreContainer;
